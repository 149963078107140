import { template as template_be3a1f04b581489dba4465e1b93be17f } from "@ember/template-compiler";
const SidebarSectionMessage = template_be3a1f04b581489dba4465e1b93be17f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
