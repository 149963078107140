import { template as template_781447fbcdf6488d98e83ddfe7338b1a } from "@ember/template-compiler";
const FKLabel = template_781447fbcdf6488d98e83ddfe7338b1a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
