import { template as template_3c66df1f8d1d4fae903c96e71f370403 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3c66df1f8d1d4fae903c96e71f370403(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
