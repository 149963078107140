import { template as template_9ec85778b8c449109beb10749d50695d } from "@ember/template-compiler";
const FKText = template_9ec85778b8c449109beb10749d50695d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
